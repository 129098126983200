export default function megaMenuNew(){

    let megaMenu = document.querySelectorAll('.js-dropdown-mega-menu-offer'),
    megaMenuChildrenClass = document.querySelectorAll('.has-children'),
    menuItem = document.querySelectorAll('.header-menu__item'),
    menuCatItem = document.querySelectorAll('.js-mega-menu-offer-cat');
    
    if(megaMenuChildrenClass){
        megaMenuChildrenClass.forEach(function(item) {
            item.addEventListener('mouseenter', function() {
                megaMenuChildrenClass.forEach(function(el) {
                    el.classList.remove('-active');
                });
                this.classList.add('-active');
    
                megaMenu.forEach(function(dropdown) {
                    dropdown.classList.remove('-active');
                });
    
                this.querySelector('.js-dropdown-mega-menu-offer').classList.add('-active');
                this.querySelector('.js-mega-menu-offer-cat.first').classList.add('-active');
                this.querySelector('.js-mega-menu-offer-box.first').classList.add('-active');
            });
        });
    }
    
    menuCatItem.forEach(function(item) {
        item.addEventListener('mouseenter', function() {
            document.querySelectorAll('.js-mega-menu-offer-box').forEach(function(box) {
                box.classList.remove('-active');
            });
    
            document.querySelectorAll('.js-mega-menu-offer-cat').forEach(function(cat) {
                cat.classList.remove('-active');
            });
    
            item.classList.add('-active');
    
            const menuId = item.getAttribute('data-menu');
            const menuBox = document.getElementById(menuId);
            if (menuBox) {
                menuBox.classList.add('-active');
            }
        });
    });
    
    document.querySelectorAll('.js-dropdown-mega-menu-offer').forEach(function(item) {
        item.addEventListener('mouseleave', function() {
            item.classList.remove('-active');
            removeActiveElements();
        });
    });
    

    if(menuItem){
        menuItem.forEach(function(item) {
            item.addEventListener('mouseenter', function() {

                if(megaMenu){
                    megaMenu.forEach(function(el){
                        if (el.classList.contains('-active') && !item.classList.contains('has-children')) {
                            el.classList.remove('-active');
                            removeActiveElements();
                        }
                    });
                }

            });
        });
    }

    function removeActiveElements(){
        document.querySelectorAll('.js-mega-menu-offer-box').forEach(function(el) {
            el.classList.remove('-active');
        });
        document.querySelectorAll('.header-menu__item').forEach(function(el) {
            el.classList.remove('-active');
        });
        document.querySelectorAll('.js-mega-menu-offer-cat').forEach(function(el) {
            el.classList.remove('-active');
        });
    }
     
}